import React from "react";
import './Banner.css';
import logo from '../resources/ball.png';

function Banner() {
	const version = process.env.REACT_APP_BUILD_DATE;

    return (
	<div className="Banner">
	    <div>
			<img src={logo} className="App-logo" alt="logo" />
			GB'S VOETBALPOULE
			<span>{version}</span>
	    </div>
	</div>
    );
}

export default Banner;